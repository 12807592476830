import {Fragment} from "react";

const NotFoundPage = () => {
    return (
        <Fragment>
            <h1>404 Not Found</h1>
        </Fragment>
    );
}

export default NotFoundPage;